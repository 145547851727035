<ug-list-status
  [listLength]="listLength"
  [isLoading]="loadingDimensions"
></ug-list-status>

@if (!loadingDimensions) {
  <div class="d-flex border-bottom mb-3 py-2 flex-wrap">
    <div class="me-3 fw-bold">Select a dimension to filter by :</div>
    <form [formGroup]="dimensionForm">
      <ng-container *ngFor="let sd of skillDimensions">
        <div class="form-check form-check-inline mb-2">
          <input
            class="form-check-input"
            type="radio"
            [attr.id]="sd.name"
            [value]="sd.id"
            formControlName="dimensionId"
          />
          <label class="form-check-label" [attr.for]="sd.name">
            {{ sd.name }}
          </label>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="overflow-visible">
    <div class="mb-2">
      <ug-dynamic-form
        [filters]="filters"
        [form]="filtersForm"
        [showClearFilters]="false"
      ></ug-dynamic-form>
    </div>
    <div class="border-top mb-2">
      <div class="d-flex align-items-center">
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary my-2 me-2"
          (click)="additionalFiltersCollapsed = !additionalFiltersCollapsed"
          [attr.aria-expanded]="!additionalFiltersCollapsed"
          aria-controls="collapseAdFilters"
        >
          {{
            additionalFiltersCollapsed
              ? 'Show Additional Filters'
              : 'Hide Additional Filters'
          }}
        </button>
        <ng-container *ngIf="additionalFiltersCollapsed">
          <div class="d-flex">
            <div *ngFor="let control of summaryControls">
              <span class="badge bg-success me-2 fs-6">{{
                control?.value?.name
              }}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="additionalFiltersCollapsed">
        <ug-dynamic-form
          [filters]="additionalFilters"
          [form]="filtersForm"
          [showClearFilters]="false"
        ></ug-dynamic-form>
      </div>
    </div>
    <div class="float-end my-1">
      <button
        [disabled]="!filtersForm.touched"
        class="btn btn-outline-secondary me-2"
        (click)="onResetClick()"
      >
        Reset
      </button>
      <button
        [disabled]="!filtersForm.valid || !selectedSkill"
        class="btn esqepBtn"
        (click)="onGenerateClick()"
      >
        Generate
      </button>
    </div>
  </div>
}
