import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  Subject,
  forkJoin,
  takeUntil,
} from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  JobRole,
  JobRoleService,
} from '../../job-role/services/job-role.service';
import { Person } from '../../person/models/person';

@Component({
  selector: 'ug-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss'],
})
export class ProjectMembersComponent implements OnInit {
  private uiService = inject(UitoolsService);
  private jobRoleService = inject(JobRoleService);
  private fb = inject(FormBuilder);
  private typeaheadService = inject(TypeaheadService);

  @Input() parentFormGroup: FormGroup;
  @Input() peopleSearch: OperatorFunction<string, Person[]>;
  @Input() jobRoleSearch: OperatorFunction<string, JobRole[]>;
  @ViewChild('membersModal') addMembersModal: ElementRef;
  private peopleListBS = new BehaviorSubject<Array<Person>>([]);
  filteredPeopleList = [];
  recommendedPeopleList = [];
  projectJobRoles;
  projectCompetencies;
  filteredJobRoleList;
  filteredCompetencyList;
  private ngUnsubscribe: Subject<boolean> = new Subject();
  jobRoleSearchRec: OperatorFunction<string, JobRole[]>;
  jobRoleFormatterRec = (result) => result['name'];
  jobRoleFormatter = (result) => result['name'];
  competencySearch: OperatorFunction<string, any[]>;
  competencyFormatter = (result) => result['competency'];
  personFormatter = (result) => result['displayName'];
  locationSearch: OperatorFunction<string, any[]>;
  locationFormatter = (result) => result['location'];
  actionUpload = '1';
  rcIsLoading = false;
  cIsLoading = false;
  loadingRcJr: boolean;
  loadingRcComp: boolean;
  locationList = [{ location: 'London' }];
  jrIndex: number;
  compIndex: number;
  competencyRecs;
  selectAllFilteredComps = false;
  peopleAdded = 0;
  selectAllFilteredPeople = false;
  jobRolesFormArray;

  @Input()
  set peopleList(value) {
    this.peopleListBS.next(value);
  }

  get peopleList() {
    return this.peopleListBS.getValue();
  }

  ngOnInit(): void {
    this.peopleListBS.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pl) => {
      if (pl) {
        this.filteredPeopleList = pl;
        this.rcIsLoading = true;
        this.cIsLoading = true;
        this.competencyRecs = this.peopleList
          .slice(0, 20)
          .map((p) => ({ ...p }));
      }
    });

    this.jobRolesFormArray = this.parentFormGroup.get(
      'projectRoles.assignedJobRoles.jobRoles',
    ) as FormArray;
    this.projectJobRoles = this.jobRolesFormArray.value;
    this.parentFormGroup
      .get('projectMembers.recModalFilters.jobRole')
      .valueChanges.subscribe((jr) => {
        this.filteredJobRoleList = this.projectJobRoles;
        if (jr !== null) {
          this.filteredJobRoleList = this.filteredJobRoleList.filter((fjr) => {
            return fjr.id === jr.id;
          });
        }
      });

    this.parentFormGroup
      .get('projectMembers.recModalFilters.competency')
      .valueChanges.subscribe((c) => {
        this.filteredCompetencyList = this.projectCompetencies;
        if (c !== null) {
          this.filteredCompetencyList = this.filteredCompetencyList.filter(
            (fc) => {
              return fc.competency === c.competency;
            },
          );
        }
      });

    this.locationSearch = this.typeaheadService.typeahead(
      this.locationList,
      ProjectMembersComponent.compareLocation,
      ProjectMembersComponent.sortLocation,
    );

    this.parentFormGroup
      .get('projectMembers.allModalFilters')
      .valueChanges.subscribe((filters) => {
        this.filteredPeopleList = this.peopleList.map((p) => ({ ...p }));
        for (const filter in filters) {
          if (filters[filter] !== '' && filters[filter] !== null) {
            this.filteredPeopleList = this.filteredPeopleList.filter((p) => {
              if (filter == 'person') {
                return p.id === filters[filter].id;
              } else if (filter == 'location') {
                return p.location === filters[filter].location;
              } else if (filter == 'jobRole') {
                return p['jobRole'] === filters[filter].name;
              } else if (filter == 'maxProjects') {
                return Number(p['activeProjects']) <= Number(filters[filter]);
              }
            });
          }
        }
      });
  }

  static sortJobRole(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  static compareJobRole(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  static sortCompetency(a, b, text: string) {
    return (
      a.competency.startsWith(text) - b.competency.startsWith(text) ||
      b.competency - a.competency
    );
  }

  static compareCompetency(items, input: string) {
    return items.competency.toLowerCase().includes(input);
  }

  static sortLocation(a, b, text: string) {
    return (
      a.location.startsWith(text) - b.location.startsWith(text) ||
      b.location - a.location
    );
  }

  static compareLocation(items, input: string) {
    return items.location.toLowerCase().includes(input);
  }

  openAddProjectMembersModal() {
    this.rcIsLoading = true;
    this.filteredJobRoleList = this.projectJobRoles;
    this.jobRoleSearchRec = this.typeaheadService.typeahead(
      this.projectJobRoles,
      ProjectMembersComponent.compareJobRole,
      ProjectMembersComponent.sortJobRole,
    );

    const observables: Observable<any>[] = [];
    // this.filteredJobRoleList.forEach((jr) => {
    //   jr.people.forEach((person) => {
    //     observables.push(
    //       this.jobRoleService.getJobRoleVsPerson(jr.id, person.personId)
    //     );
    //   });
    // });

    forkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((x) => {
        this.filteredJobRoleList.forEach((jr) => {
          jr.isSelected = false;
          jr.people.forEach((person) => {
            person.roleName = jr.name;
            person.roleId = jr.id;
            person.roleIndex = jr.roleIndex;
            person.location = 'London';
            person.activeProjects = Math.floor(Math.random() * 6);
            const index = x.findIndex(
              (p) => p.personId === person.personId && p.id === jr.id,
            );
            person.personRoleScore = (x[index].personRoleScore * 100).toFixed(
              1,
            );
            person.summary = x[index].summary;
          });
          jr.rec = jr.people
            .filter((person) => {
              const isProjectMember = this.projectMembers.value.some(
                (member) => {
                  return (
                    member.personId === person.personId &&
                    member.roleIndex === person.roleIndex
                  );
                },
              );
              return (
                person.personRoleScore >= jr.minRoleMatch && !isProjectMember
              );
            })
            .map((person) => {
              return { ...person, isSelected: false };
            });
        });
        this.rcIsLoading = false;
      });

    this.cIsLoading = true;
    const competencies = this.parentFormGroup.get(
      'projectCompetencies.assignedCompetencies.competencies',
    ) as FormArray;
    this.projectCompetencies = competencies.value;
    this.filteredCompetencyList = this.projectCompetencies;
    this.competencySearch = this.typeaheadService.typeahead(
      this.projectCompetencies,
      ProjectMembersComponent.compareCompetency,
      ProjectMembersComponent.sortCompetency,
    );

    this.filteredCompetencyList.forEach((competency) => {
      competency.isSelected = false;
      let index = competency.levels.findIndex(
        (l) => competency.requiredLevel === l.levelCode,
      );
      console.log(index);
      competency.requiredLevelName = competency.levels[index].name;
      competency.rec = this.competencyRecs
        .map((competencyRec) => ({
          personId: competencyRec.id,
          isSelected: false,
          personUPN: competencyRec.adUPN,
          displayName: competencyRec.displayName,
          location: 'London',
          activeProjects: Math.floor(Math.random() * 6),
          level:
            competency.levels[
              Math.floor(Math.random() * competency.levels.length)
            ],
        }))
        .filter((cr) => {
          if (
            this.projectMembers.value.some((el) => el.personId === cr.personId)
          ) {
            return;
          } else {
            return cr.level.levelCode >= competency.requiredLevel;
          }
        });
    });

    this.filteredPeopleList.forEach((p) => {
      p.isSelected = false;
      if (!this.projectMembers.value.some((el) => el.personId === p.id)) {
        // Filter out the person if not present in projectMembers
        return;
      }
    });

    this.cIsLoading = false;
    this.uiService.openModalLarge(this.addMembersModal);
  }

  get projectMembers(): FormArray {
    return this.parentFormGroup.get(
      'projectMembers.assignedMembers.projectMembers',
    ) as FormArray;
  }

  newMember(person): FormGroup {
    return this.fb.group({
      personId: person.personId ?? person.id,
      personUPN: person.personUPN,
      name: person.displayName,
      location: person.location,
      score: person.personRoleScore,
      summary: [person.summary],
      roleName: person.roleName,
      roleId: person.roleId,
      roleIndex: person.roleIndex,
    });
  }

  removeMember(index) {
    this.projectMembers.removeAt(index);
  }

  watchForChanges(i: number) {
    const roleIndex = this.projectMembers.at(i).get('roleIndex').value;
    const index = this.projectJobRoles.findIndex((x) => {
      return Number(x.roleIndex) === Number(roleIndex);
    });

    const jrId = this.projectJobRoles[index].id;
    const pId = this.projectMembers.at(i).get('personId').value;

    this.jobRoleService
      .getJobRole(jrId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((x) => {
        this.projectMembers.at(i).get('roleName').setValue(x.name);
        this.projectMembers.at(i).get('roleId').setValue(jrId);
      });

    // this.jobRoleService
    //   .getJobRoleVsPerson(jrId, pId)
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((x) => {
    //     let score = Math.round(x.personRoleScore * 100);
    //     this.projectMembers.at(i).get('score').setValue(score);
    //     this.projectMembers.at(i).get('summary').setValue(x.summary);
    //   });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  toggleJr(i: number) {
    this.jrIndex = this.jrIndex === i ? null : i;
    this.loadingRcJr = true;
  }

  toggleComp(i: number) {
    this.compIndex = this.compIndex == i ? null : i;
    this.loadingRcComp = true;
  }

  getJobRecs(i) {
    return this.projectJobRoles[i].jobRole.rec;
  }

  get listLength() {
    return this.rcIsLoading ? 0 : 1;
  }

  get clistLength() {
    return this.cIsLoading ? 0 : 1;
  }

  getJobRoleMembersCount(jr) {
    return this.projectMembers.value.filter(
      (pm) => pm.roleIndex === jr.roleIndex,
    ).length;
  }

  clearFiltersRoles() {
    this.parentFormGroup.get('projectMembers.recModalFilters.jobRole').reset();
  }

  clearFiltersComps() {
    this.parentFormGroup
      .get('projectMembers.recModalFilters.competency')
      .reset();
  }

  clearFiltersAll() {
    this.parentFormGroup.get('projectMembers.allModalFilters').reset();
  }

  checkUncheckAllFilteredJobRecs(i) {
    this.filteredJobRoleList[i].rec.forEach((p) => {
      p['isSelected'] = this.filteredJobRoleList[i].isSelected;
    });
  }

  isAllFilteredJobRecsSelected(i) {
    this.filteredJobRoleList[i].isSelected = this.filteredJobRoleList[
      i
    ].rec.every((p: any) => {
      return p['isSelected'] === true;
    });
  }

  checkUncheckAllFilteredCompRecs(i) {
    this.filteredCompetencyList[i].rec.forEach((p) => {
      p['isSelected'] = this.filteredCompetencyList[i].isSelected;
    });
  }

  isAllFilteredCompRecsSelected(i) {
    this.filteredCompetencyList[i].isSelected = this.filteredCompetencyList[
      i
    ].rec.every((p: any) => {
      return p['isSelected'] === true;
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addSelectedPeople() {
    this.peopleAdded = 0;
    const radioOption = this.parentFormGroup.get(
      'projectMembers.radioOption',
    ).value;
    if (radioOption === 'rec') {
      const selectedRecMembers = [];
      this.filteredCompetencyList.forEach((c) => {
        const cSelectedPeople = c.rec.filter((p) => p.isSelected);
        selectedRecMembers.push(...cSelectedPeople);
        c.rec = c.rec.filter((p) => !p.isSelected);
      });
      this.filteredJobRoleList.forEach((jr) => {
        const jrSelectedPeople = jr.rec.filter((p) => p.isSelected);
        selectedRecMembers.push(...jrSelectedPeople);
        jr.rec = jr.rec.filter((p) => !p.isSelected);
      });

      this.peopleAdded += selectedRecMembers.length;
      selectedRecMembers.forEach((p) => {
        this.projectMembers.push(this.newMember(p));
      });
    } else if (radioOption === 'all') {
      const selectedMembers = this.filteredPeopleList.filter(
        (p) => p.isSelected,
      );
      this.peopleAdded += selectedMembers.length;
      selectedMembers.forEach((p) => {
        this.projectMembers.push(this.newMember(p));
      });
      this.filteredPeopleList.forEach((p) => {
        p.isSelected = false;
      });
    }

    let message = 'Successfully added ' + this.peopleAdded;

    if (this.peopleAdded > 1) {
      message += ' people';
    } else if (this.peopleAdded === 1) {
      message += ' person';
    }

    this.uiService.showToast(message, {
      classname: 'bg-success text-light',
      delay: 3000,
    });
  }

  checkUncheckAllFilteredPeople() {
    this.filteredPeopleList.forEach((c) => {
      c['isSelected'] = this.selectAllFilteredPeople;
    });
  }

  isAllFilteredPeopleSelected() {
    this.selectAllFilteredPeople = this.filteredPeopleList.every((c: any) => {
      return c['isSelected'] === true;
    });
  }
}
