import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SkillTrainingSession } from '../../skill/skill-training/skill-training-session';
import { IncompleteClaim } from '../incomplete-express-class/incomplete-claim';
import { PerfGridContent } from '../perf-grid-content/perf-grid-content';
import { SkillsMatrixSummary } from '../skill-matrix-summary/skill-matrix-summary';
import {
  SkillsMatrixCurricula,
  SkillsMatrixSubject,
} from '../skill-matrix/skill-matrix';
import { TrainingSubjectCategories } from '../training-subject-categories/training-subject-categories';

export abstract class PersonStatusSummary {
  personStatusSummary: Array<{ statusCount: number; statusText: string }>;
}

export interface ClaimItemVsCategory {
  categoryId: number;
  categoryName: string;
  itemSummary: ItemSummary[];
}

export interface ItemSummary {
  itemId?: number;
  itemName: string;
  count: number;
}

export interface ClaimCategoryVsGroup {
  groupId?: number;
  groupName?: string;
  groupSummary: Array<ClaimItemVsCategory>;
}

export interface ExpressClassUserObs {
  status: string;
  score: number;
  comment: string;
  externalId: number;
}

export interface CsodExpressClassPayload {
  facilitatorUserId: number;
  userStatus: Array<ExpressClassUserObs>;
  loId: string;
}

export interface CsodExpressClassResponse {
  status: string;
  timestamp: Date;
  correlationId: string;
  data: {
    expressClassId: string;
    proxyId: number;
    usersSubmitted: number;
  };
}

export interface CsodLearningAssignmentUser {
  id: number;
  includeSubordinates: boolean;
}

export interface CsodLearningAssignmentOrgUnit {
  externalOuId: string;
  externalType: string;
  includeSubordinates: boolean;
}

export interface CsodLearningAssignmentPayload {
  name: string;
  learningObjectIds: string[];
  users: CsodLearningAssignmentUser[];
  description: string;
  comments: string;
  assignmentStatus: string;
  bypassUserPayment?: boolean;
  emailOption?: string;
  sendAssignTrainingEmails?: boolean;
  sendRegisterTrainingEmails?: boolean;
  assignNewOccurrence?: boolean;
  maintainProgress?: boolean;
  overridePriorDueDates?: boolean;
  dueDate?: Date;
  jobRoleId?: number;
  orgUnits?: CsodLearningAssignmentOrgUnit[];
  orgUnitsInternal?: CsodLearningAssignmentOrgUnit[];
}

export interface CsodLearningAssignmentResponse {
  status: string;
  timestamp: string;
  assignment: {
    id: number;
  };
}

export interface CsodSessionPart {
  PartName: string;
  PartLocation: string;
  PartStartDateTime: string;
  PartEndDateTime: string;
  PartOccurrence: string;
}

export interface CsodCreateSessionPayload {
  EventLoRef: string;
  Parts: CsodSessionPart[];
}

export interface CsodCreateSessionResponseData {
  LOID: string;
  Provider_LO_ID: string;
  ProviderId: string;
  Result: string;
  Reason: string;
}

export interface CsodCreateSessionResponseRecord {
  loId: string;
  reason: string;
  result: string;
}

export interface CsodCreateSessionResponse {
  Validations: string;
  status: number;
  timeStamp: string;
  createdRecords: number;
  data: CsodCreateSessionResponseData[];
  records: CsodCreateSessionResponseRecord[];
}

export interface AnalyticsStatus {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

export interface AnalyticsFilter {
  skillId: number;
  analyticStatuses: Array<number>;
  claimLevelId: number;
  fromDate: Date;
  toDate: Date;
  orgUnitIds: number[];
  jobRoleIds: number[];
  skillName: string;
}

export interface AnalyticsResults {
  counts: Array<AnalyticsCount>;
  records: Array<AnalyticsRecord>;
}

export interface AnalyticsCount {
  analyticStatusId: number;
  analyticStatus: string;
  count: number;
}

export interface AnalyticsRecord {
  personId: number;
  personName: string;
  managerName: string;
  positionName: string;
  locationName: string;
  analyticStatusId: number;
  analyticStatus: string;
  claimId: number;
  claimStatus: string;
  claimLevel: string;
  isPublished: boolean;
  completionDate: Date;
  expiryDate: Date;
  trainedDate: Date;
}

export enum TrainingAnalyticsStatusKey {
  Active = '10',
  Expired = '20',
  Expiring = '30',
  Retrained = '40',
  Other = '90',
}

export enum CompetencyAnalyticsStatusKey {
  Assessed = '10',
  Expired = '20',
  Expiring = '30',
  Open = '50',
  Other = '90',
}

export interface GenerateAnalysisEvent {
  filters: AnalyticsFilter;
  skillType: string;
  dimensionId: number;
}

const apiServerUri = environment.apiUrl;
const dotNetServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private http = inject(HttpClient);

  dashboardDataFeed: BehaviorSubject<Array<ClaimCategoryVsGroup>> =
    new BehaviorSubject<Array<ClaimCategoryVsGroup>>([]);

  mockCurricula: Array<SkillsMatrixCurricula> = [
    { name: 'SP12 MDU AND EQUIP', category: 'SAS', subjects: {} },
    { name: 'SP3 SW TECH', category: 'SAS', subjects: {} },
    { name: 'SP6 CSU O', category: 'SAS', subjects: {} },
    { name: 'SP6 TRU CH 0', category: 'SAS', subjects: {} },
    { name: 'SP6 WF U 0', category: 'SAS', subjects: {} },
    // {name: 'OS FI TIER 1', category: 'OS', subjects: {}},
    // {name: 'OS IRS', category: 'OS', subjects: {}},
    { name: 'IC CSRO', category: 'IC', subjects: {} },
    { name: 'IC GA-AM', category: 'IC', subjects: {} },
    { name: 'IC GA-CM', category: 'IC', subjects: {} },
    { name: 'IC GA-GM', category: 'IC', subjects: {} },
    { name: 'IC GA-SM', category: 'IC', subjects: {} },
    { name: 'EFAD', category: 'EFAD', subjects: {} },
    { name: 'EFAD INITIAL', category: 'EFAD', subjects: {} },
    { name: 'EFAD LGV', category: 'EFAD', subjects: {} },
    { name: 'EFAD LGV CAT C', category: 'EFAD', subjects: {} },
    { name: 'EFAD REFRESH', category: 'EFAD', subjects: {} },
    { name: 'CT SW FFR1', category: 'CORP', subjects: {} },
    { name: 'CT SW FR BO I', category: 'CORP', subjects: {} },
    { name: 'CT SW FR BO IN', category: 'CORP', subjects: {} },
    { name: 'CT SW FR BO R', category: 'CORP', subjects: {} },
  ];

  mockCurriculaSubjects: Array<SkillsMatrixSubject> = [
    {
      skillName: 'Training Item 1',
      deepLinkUrl: '',
      learningObjectId: 'e23d934a-96df-4366-8a09-b021e7f77861',
      users: {},
    },
    {
      skillName: 'Training Item 2',
      deepLinkUrl: '',
      learningObjectId: '77ed1d70-0b69-41c4-bd95-1d5411297891',
      users: {},
    },
    {
      skillName: 'Training Item 3',
      deepLinkUrl: '',
      learningObjectId: 'f31d7dc3-0081-4d7f-b594-e66d8761e61b',
      users: {},
    },
    {
      skillName: 'Training Item 4',
      deepLinkUrl: '',
      learningObjectId: 'cfba52c8-5d50-46be-99eb-23e3684f787c',
      users: {},
    },
  ];

  getClaimStatusSummaryVsRole(
    personId: number,
  ): Observable<Array<ClaimCategoryVsGroup>> {
    return this.http
      .get(`${apiServerUri}/people/${personId}/status-summary-vs-role`)
      .pipe(
        map((resp) => {
          this.dashboardDataFeed.next(resp['claimStatusVsRole'] || []);
          return resp['claimStatusVsRole'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  createCsodExpressClass(
    expressClassPayload: CsodExpressClassPayload,
  ): Observable<CsodExpressClassResponse> {
    // TODO: Move to separate service
    return this.http
      .post(`${apiServerUri}/functions/csod/express-class`, expressClassPayload)
      .pipe(
        map((resp: CsodExpressClassResponse) => resp['expressClassResponse']),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  createCsodLearningAssignment(
    learningAssignmentPayload: CsodLearningAssignmentPayload,
  ): Observable<CsodLearningAssignmentResponse> {
    // TODO: Move to separate service
    return this.http
      .post(
        `${apiServerUri}/functions/csod/learning-assignment`,
        learningAssignmentPayload,
      )
      .pipe(
        map((resp: CsodLearningAssignmentResponse) => {
          return resp['learningObjectAssigmnet'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  // createCsodSession(sessionPayload: CsodCreateSessionPayload ): Observable<CsodCreateSessionResponse> {
  createCsodSession(
    sessionPayload: CsodCreateSessionPayload,
  ): Observable<SkillTrainingSession> {
    // TODO: Move to separate service
    return this.http
      .post(`${apiServerUri}/functions/csod/create-lo/session`, sessionPayload)
      .pipe(
        map((resp: CsodCreateSessionResponse) => {
          return resp['CreateLoSession'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsMatrixSummary(): Observable<SkillsMatrixSummary> {
    return this.http.get(`${apiServerUri}/dashboard/skill-matrix-summ`).pipe(
      map((resp: SkillsMatrixSummary) => {
        return resp;
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getTrainingSubjectCategories(): Observable<Array<TrainingSubjectCategories>> {
    return this.http
      .get(`${apiServerUri}/dashboard/training-subject-categories`)
      .pipe(
        map((resp) => {
          return resp['trainingSubjectCategories'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfSite(
    site: string,
    firstName: string,
    lastName: string,
  ): Observable<PerfGridContent> {
    let params: HttpParams = new HttpParams();
    if (site) {
      params = params.append('site', site);
    }
    if (firstName) {
      params = params.append('firstName', firstName);
    }
    if (lastName) {
      params = params.append('lastName', lastName);
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-area-location`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfTeam(
    subSite: string,
    idOrfirstName: number | string,
    lastName?: string,
  ): Observable<PerfGridContent> {
    let params: HttpParams = new HttpParams();
    if (subSite) {
      params = params.append('site', subSite);
    }
    if (typeof idOrfirstName === 'number') {
      params = params.append('personId', idOrfirstName.toString());
    } else {
      if (idOrfirstName) {
        params = params.append('firstName', idOrfirstName.toString());
      }
      if (lastName) {
        params = params.append('lastName', lastName);
      }
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-site-team`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfManager(site: string, personId: number) {
    let params: HttpParams = new HttpParams();
    params = params.append('personId', personId.toString());
    if (site) {
      params = params.append('site', site);
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-site-team`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfAgent(
    firstName: string,
    lastName: string,
  ): Observable<PerfGridContent>;
  getPerfAgent(personId: number): Observable<PerfGridContent>;
  getPerfAgent(p1: number | string, p2?: string): Observable<PerfGridContent> {
    let params: HttpParams = new HttpParams();
    if (typeof p1 === 'number') {
      params = params.append('personId', p1.toString());
    } else {
      if (p1) {
        params = params.append('firstName', p1.toString());
      }
      if (p2) {
        params = params.append('lastName', p2);
      }
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-agent`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfRecruitTeam(roleName: string, personId: number) {
    let params: HttpParams = new HttpParams();
    params = params.append('personId', personId.toString());
    if (roleName) {
      params = params.append('site', roleName);
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-recruit-team`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPerfProjCoord(roleName: string, personId: number) {
    let params: HttpParams = new HttpParams();
    params = params.append('personId', personId.toString());
    if (roleName) {
      params = params.append('site', roleName);
    }

    return this.http
      .get(`${apiServerUri}/performance/perf-project-coord`, { params: params })
      .pipe(
        map((resp: PerfGridContent) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getDisplayPercentage(percentComplete: number): string {
    let scoreText = 'No Data';
    if (percentComplete !== null) {
      scoreText = percentComplete.toLocaleString('en', {
        style: 'percent',
        maximumFractionDigits: 1,
      });
    }
    return scoreText;
  }

  IEStyle(calculatedHeight: number): any {
    /**
     * Supply an explicitly calculated height style attribute for IE browsers to overcome
     * scaling issues with SVG and the default height:auto in Bootstrap's .img-fluid class
     */
    let IESVGStyle = {};
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.userAgent.indexOf('Trident/') > 0
    ) {
      IESVGStyle = { height: calculatedHeight };
    }
    return IESVGStyle;
  }

  getIncompleteClaims(): Observable<IncompleteClaim[]> {
    return this.http.get(`${apiServerUri}/claim/express-class/history`).pipe(
      map((resp) => {
        return resp['claimExpressClass'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  resubmitExpressClass(
    expressClassId: string,
  ): Observable<CsodExpressClassResponse> {
    return this.http
      .post(
        `${apiServerUri}/claim/express-class/${expressClassId}/resubmit`,
        null,
      )
      .pipe(
        map((resp) => resp['expressClassResponse']),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }
  updateExpressClassStatus(
    expressClassId: string,
    statusMessage: string,
  ): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .put(
        `${apiServerUri}/claim/express-class/${expressClassId}/status`,
        JSON.stringify({ status: statusMessage }),
        { headers: headers },
      )
      .pipe(
        map((resp) => true),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getCompetencyTrainingAnalyticsData(
    dimensionType: string,
    filterData: AnalyticsFilter,
  ): Observable<AnalyticsResults> {
    return this.http.post<AnalyticsResults>(
      `${dotNetServerUri}/skillAnalytics/${dimensionType}`,
      filterData,
    );
  }

  getSkillAnalyticsTrainingStatuses(): Observable<AnalyticsStatus[]> {
    return this.http.get<AnalyticsStatus[]>(
      `${dotNetServerUri}/skillAnalytics/training/statuses`,
    );
  }

  getSkillAnalyticsCompetencyStatuses(): Observable<AnalyticsStatus[]> {
    return this.http.get<AnalyticsStatus[]>(
      `${dotNetServerUri}/skillAnalytics/competency/statuses`,
    );
  }

  getAnalyticsCsv(
    dimensionType: string,
    filterData: AnalyticsFilter,
  ): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      `${dotNetServerUri}/skillAnalytics/${dimensionType}/csv`,
      filterData,
      {
        headers: headers,
        responseType: 'blob',
      },
    );
  }
}
