import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../core/services/auth.service';
import { ConfigService } from '../../../core/services/config.service';
import {
  MatrixGroupedSkills,
  MatrixPerson,
  MatrixPersonClaim,
  MatrixService,
  MatrixSkill,
  MatrixType,
} from '../matrix.service';

@Component({
  selector: 'ug-user-task-status',
  templateUrl: './user-task-status.component.html',
  styleUrls: ['./user-task-status.component.scss'],
})
export class UserTaskStatusComponent implements OnInit {
  configService = inject(ConfigService);
  modal = inject(NgbActiveModal);
  matrixService = inject(MatrixService);
  private authService = inject(AuthService);

  @Input() skill: MatrixSkill;
  @Input() person: MatrixPerson;
  @Input() groupedSkills: MatrixGroupedSkills;
  @Input() fullFunc = false;
  @Input() isManager = false;
  @Input() matrixType;

  isCsodUser: boolean;
  claim: MatrixPersonClaim;
  statusDescription = '';
  eventType: string;

  @Output() deepLinkClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() confirmCompleteClicked: EventEmitter<string> =
    new EventEmitter<string>();

  awaitingCompleteConfirmation = false;
  launchText: string;

  ngOnInit(): void {
    this.eventType = MatrixService.EVENT;
    this.claim = this.skill.claims.find((c) => c.personId === this.person.id);
    if (!this.claim.state) {
      this.claim.state = 'Not Activated';
    }
    this.isCsodUser = this.person.externalUserId !== null;
    this.launchText =
      this.matrixType === MatrixType.Training
        ? 'Launch Training'
        : 'Launch Assessment';
  }

  showTrainingDeepLink(person) {
    return person.id === this.authService.me.id;
  }

  showCompetencyLink(person) {
    return person.id === this.authService.me.id || this.skill.userCanAccess;
  }

  routeToAssessmentRequest() {
    if (this.claim.id) {
      if (this.claim.id === this.authService.me.id) {
        return 'assessment-request/' + this.claim.id + '/assessor';
      } else {
        return 'assessment-request/' + this.claim.id + '/claimant';
      }
    } else {
      return '/assessment-request/new';
    }
  }

  onLinkClick() {
    this.deepLinkClicked.emit('Awaiting Sync');
  }

  onConfirmCompleteClick() {
    this.confirmCompleteClicked.emit('Complete');
    this.awaitingCompleteConfirmation = false;
  }

  showDueDate() {
    return this.matrixType === MatrixType.Training;
  }
}
