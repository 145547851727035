<div class="card overflow-visible shadow">
  <div class="card-body">
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading">
      <form>
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="activeTab"
          [destroyOnHide]="false"
          class="nav-justified"
        >
          <li [ngbNavItem]="1" id="details">
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Group Details & Filters</h6>
              <h6>
                <small
                  >Set the name, description and filters for the group</small
                >
              </h6>
            </a>
            <ng-template ngbNavContent>
              <ug-group-details
                [groupFiltersForm]="groupFiltersForm"
                [gettingFilteredPeople]="tableLoading"
                [peopleCount]="peopleCount"
                [groupFilterId]="groupFilterId"
                [discardChangesSubj]="discardChangesSubj"
                [allowEditGroup]="allowEditGroup"
              ></ug-group-details>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" id="competencies">
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Review</h6>
              <h6><small>Review people based on the applied filters</small></h6>
            </a>
            <ng-template ngbNavContent>
              <ug-group-review
                [peopleCount]="peopleCount"
                [filters]="tableFilters"
                [tableRows]="tableRows"
                [tablePageSize]="tablePageSize"
                [tablePageIndex]="tablePageIndex"
                [tableLoading]="tableLoading"
                [filterSummary]="filterSummary"
                [groupFilterId]="groupFilterId"
                (pageIndexChange)="updateData($event)"
                (pageSizeChange)="updateData(1, $event)"
                (sortChange)="updateData(1, null, $event)"
                (filtersCleared)="clearFilters($event)"
              ></ug-group-review>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </form>
    </div>

    <div class="card-footer bg-white border-0" *ngIf="!isLoading">
      <div class="d-flex justify-content-end">
        <button
          type="button"
          *ngIf="groupFiltersForm.dirty && allowEditGroup"
          class="btn btn-outline-secondary ms-2"
          (click)="discardChanges()"
        >
          Discard Changes
        </button>
        <button
          *ngIf="allowEditGroup"
          [disabled]="!groupFiltersForm.dirty || groupFiltersForm.invalid"
          type="button"
          title="Save"
          class="btn btn-outline-success ms-2"
          (click)="onSaveClick()"
        >
          Save <i *ngIf="savingSpinner" class="fa fa-spinner fa-spin"></i>
        </button>
        <button
          type="button"
          *ngIf="activeTab !== 1"
          title="Previous"
          [disabled]="activeTab === 1"
          class="btn esqepBtn mx-2"
          (click)="selectPreviousTab()"
        >
          Previous
        </button>
        <button
          type="button"
          *ngIf="activeTab !== 2"
          title="Next"
          class="btn esqepBtn ms-2"
          (click)="selectNextTab()"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
