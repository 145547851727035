import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import { TableHeader } from '../../../controls/table/table.service';

@Component({
  selector: 'ug-group-review',
  templateUrl: './group-review.component.html',
  styleUrls: ['./group-review.component.scss'],
})
export class GroupReviewComponent {
  @Input() tableLoading: boolean;
  @Input() groupFilterId: number;
  @Input() tablePageSize: any;
  @Input() tablePageIndex: any;
  @Input() filters: Array<FilterBase<any>>;
  @Input() tableRows: Array<any>;

  public isCollapsed = true;
  @Input() filterSummary = [];
  @Input() peopleCount: number;
  @Output() readonly filtersCleared = new EventEmitter<boolean>();
  @Output() readonly pageIndexChange = new EventEmitter<number>();
  @Output() readonly pageSizeChange = new EventEmitter<number>();
  @Output() readonly sortChange = new EventEmitter<{
    column: string;
    sortDirection: string;
  }>();

  headers: Array<TableHeader> = [{ id: 'displayName', title: 'Name' }];

  onPageChange(pageNumber) {
    this.pageIndexChange.emit(pageNumber);
  }

  onPageSizeChange(pageNumber) {
    this.pageSizeChange.emit(pageNumber);
  }

  onSortChange($event: { column: string; sortDirection: string }) {
    this.sortChange.emit($event);
  }

  clearFilters($event: boolean) {
    this.filtersCleared.emit(true);
  }
}
