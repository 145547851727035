import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { TextboxFilter } from '../../../controls/dynamic-form/control-types/filter-textbox';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { AuthService } from '../../../core/services/auth.service';
import { UitoolsService } from '../../../core/services/uitools.service';
import {
  AttendeesAndRecommendations,
  EventSession,
  EventsService,
  TrainingEventPerson,
} from '../../../events/services/events.service';
import { AddAttendeesComponent } from '../add-attendees/add-attendees.component';

@Component({
  selector: 'ug-manage-session',
  templateUrl: './manage-session.component.html',
  styleUrls: ['./manage-session.component.scss'],
})
export class ManageSessionComponent implements OnInit {
  @Input() currentSession: EventSession;
  @Input() currentSessionId: number;
  @Input() userHasAdminAccess = false;

  modal = inject(NgbActiveModal);
  private eventsService = inject(EventsService);
  private ngbModal = inject(NgbModal);
  private router = inject(Router);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private uiService = inject(UitoolsService);
  private tableLoading = false;
  private originalAttending: TrainingEventPerson[] = [];
  private originalRecommended: TrainingEventPerson[] = [];
  private newAttendees: number[] = [];
  private removedAttendees: number[] = [];
  private destroyRef = inject(DestroyRef);
  // private isStartEndDateInSameDay = true;

  protected recommended: TrainingEventPerson[] = [];
  protected attending: TrainingEventPerson[] = [];
  protected attendingIsLoading = true;
  protected recommendedIsLoading = true;
  protected spin = false;
  protected saveDisabled = true;
  protected isSessionCompleted = false;
  protected instructors: string;
  protected hasSessionEnded = false;
  protected attendingHeaders: TableHeader[];
  protected recommendedHeaders: TableHeader[];
  protected attendingTableButtons: TableSelectedButton[];
  protected recommendedSelectedButtons: TableSelectedButton[];

  exceptionData = {
    SESSION_ATTENDEES: {
      level: AlertLevels.ERROR,
      code: 'MS-001',
      message: 'Error retrieving session attendees',
    } as AlertData,
    SESSION_ADD_ATTENDEES: {
      level: AlertLevels.ERROR,
      code: 'MS-002',
      message: 'Error adding session attendees',
    } as AlertData,
    SESSION_REMOVE_ATTENDEES: {
      level: AlertLevels.ERROR,
      code: 'MS-003',
      message: 'Error removing session attendees',
    } as AlertData,
  };

  filters = [
    new TextboxFilter({
      key: 'searchTerm',
      label: 'Filter by term',
      order: 1,
      placeholder: 'Filter by term',
    }),
  ];

  expiryStatus = (row) => {
    const statusIcon = 'fas fa-xl';
    const currentDate = new Date();
    if (!row.hasOwnProperty('trainingExpiryDate')) {
      return null;
    }

    if (row.trainingExpiryDate === 'n/a') {
      return `${statusIcon} fa-times-circle text-danger`;
    } else if (row.trainingExpiryDate < currentDate) {
      return `${statusIcon} fa-times-circle text-danger`;
    } else if (
      row.trainingExpiryDate >= currentDate &&
      row.trainingExpiryDate <
        new Date(currentDate.setMonth(currentDate.getMonth() + 3))
    ) {
      return `${statusIcon} fa-times-circle text-warn`;
    }

    return `${statusIcon} fa-check-circle text-success`;
  };

  hideStartAssessment = () => {
    return (
      new Date(this.currentSession.endDate) > new Date() || !this.saveDisabled
    );
  };

  isSessionInThePast = () => {
    return this.hasSessionEnded;
  };
  disableStartAssessment = () => {
    const amIAttending = this.attending.find(
      (f) => f.id === this.authService.me.id,
    )
      ? true
      : false;
    if (amIAttending) {
      const startAssessment = this.attendingTableButtons?.find(
        (f) => f.title === 'Start assessment',
      );
      if (startAssessment) {
        startAssessment.disabledMessage = `Unable to start assessment. ${this.authService.me.displayName} cannot assess their own claims.`;
      }
    }
    return amIAttending ? true : !this.saveDisabled;
  };
  disabledMessage = () => {
    return !this.isSessionInThePast()
      ? 'Please save changes in order to update the roster'
      : 'Please save changes in order to start the assessment';
  };

  prereqCompleted = (row) => {
    return row.completedPrerequisites
      ? 'fas fa-xl fa-check-circle text-success'
      : 'fas fa-xl fa-times-circle text-danger';
  };

  startAssessment = () => {
    this.eventsService.session = this.currentSession;
    this.eventsService.selectedAttendees = this.attending;
    this.modal.close('');
    this.router.navigate(['bulk-assessment', this.currentSession.id]);
  };

  removeFromSession = (selected) => {
    this.attending = this.attending.filter((attendee) => {
      return !selected.find((removedAttendee) => {
        return attendee.id === removedAttendee.id;
      });
    });

    this.recommended = this.filterAttendingRecommendedUsers();
    this.saveDisabled = false;
  };

  ngOnInit(): void {
    this.tableLoading = true;

    if (this.currentSession) {
      if (this.currentSession.isSessionCompleted) {
        this.isSessionCompleted = true;
      } else {
        this.hasSessionEnded =
          new Date(this.currentSession.endDate) < new Date();
        this.getSessionAttendees(this.currentSession.id);
      }
      this.instructors = this.currentSession.instructors
        .map((i) => i.displayName)
        .join(', ');
    } else {
      if (this.currentSessionId) {
        this.eventsService
          .getTrainingSessionById(this.currentSessionId)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((x) => {
            this.currentSession = x;
            this.hasSessionEnded =
              new Date(this.currentSession.endDate) < new Date();
            this.instructors = this.currentSession.instructors
              .map((i) => i.displayName)
              .join(', ');
          });

        this.getSessionAttendees(this.currentSessionId);
      }
    }
    this.attendingHeaders = [
      { id: 'displayName', title: 'Name' },
      { id: 'location', title: 'Location' },
      {
        id: 'trainingExpiryDate',
        title: 'Expiry Date',
        dateFormat: 'dd/MM/yyyy',
      },
      {
        id: 'expiryStatus',
        title: 'Expiry Status',
        iconFunction: this.expiryStatus,
        class: 'text-center',
      },
      {
        id: 'completedPrerequisites',
        title: 'Prerequisite Learning Completed',
        iconFunction: this.prereqCompleted,
        class: 'text-center',
      },
      {
        id: 'attended',
        title: 'Attended',
        checkbox: true,
        class: 'text-center',
        hideCondition: this.hideStartAssessment,
        checkboxFunction: this.checkboxChanged,
      },
    ];

    this.recommendedHeaders = [
      { id: 'displayName', title: 'Name' },
      { id: 'location', title: 'Location' },
      {
        id: 'trainingExpiryDate',
        title: 'Expiry Date',
        dateFormat: 'dd/MM/yyyy',
      },
      {
        id: 'expiryStatus',
        title: 'Expiry Status',
        iconFunction: this.expiryStatus,
        class: 'text-center',
      },
      {
        id: 'completedPrerequisites',
        title: 'Prerequisite Learning Completed',
        iconFunction: this.prereqCompleted,
        class: 'text-center',
      },
    ];

    this.attendingTableButtons = [
      {
        title: 'Remove selected',
        class: 'btn-primary me-2',
        function: this.removeFromSession,
        hideCondition: this.isSessionInThePast,
      },
      {
        title: 'Start assessment',
        class: 'btn-success',
        hideCondition: this.hideStartAssessment,
        function: this.startAssessment,
        disabledCondition: this.disableStartAssessment,
        disabledMessage: this.disabledMessage(),
      },
    ];

    this.recommendedSelectedButtons = [
      {
        title: 'Add Selected',
        class: 'btn-success',
        function: this.addSelectedPersonToSession,
      },
    ];
  }

  public saveChanges(): void {
    this.spin = true;

    this.attending.forEach((at) => {
      if (!this.originalAttending.find((oa) => oa.id === at.id)) {
        this.newAttendees.push(at.id);
      }
    });

    this.originalAttending.forEach((at) => {
      if (!this.attending.find((oa) => oa.id === at.id)) {
        this.removedAttendees.push(at.id);
      }
    });

    const observables: Observable<any>[] = [];

    if (this.newAttendees && this.newAttendees.length > 0) {
      observables.push(
        this.eventsService
          .addAttendees(this.currentSession.id, this.newAttendees)
          .pipe(
            catchError((err) => {
              this.alertService.createAlert2(
                this.exceptionData.SESSION_ADD_ATTENDEES,
                err,
              );
              return of(undefined);
            }),
          ),
      );
    }

    if (this.removedAttendees && this.removedAttendees.length > 0) {
      observables.push(
        this.eventsService
          .removeAttendees(this.currentSession.id, this.removedAttendees)
          .pipe(
            catchError((err) => {
              this.alertService.createAlert2(
                this.exceptionData.SESSION_REMOVE_ATTENDEES,
                err,
              );
              return of(undefined);
            }),
          ),
      );
    }

    forkJoin(observables).subscribe((resp) => {
      this.uiService.showToast(
        `Successfully updated attendees for session ${this.currentSession.title}`,
        { classname: 'bg-success text-light', delay: 3000 },
      );
      this.saveDisabled = true;
      this.removedAttendees = [];
      this.newAttendees = [];
      this.spin = false;
    });
  }

  openAddAttendeeModal() {
    const modalRef = this.ngbModal.open(AddAttendeesComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'xxl',
    });
    modalRef.componentInstance.peopleIdsInSession = this.attending.map(
      (a) => a.id,
    );
    modalRef.componentInstance.peopleAddedToSession.subscribe((selected) => {
      this.addSelectedPersonToSession(selected);
    });
  }

  checkboxChanged = (person) => {
    const index = this.attending.findIndex((x) => x.id === person.id);
    this.attending[index] = person;
  };

  addSelectedPersonToSession = (selected) => {
    selected.map((s) => {
      s['attended'] = true;
      return s;
    });

    this.attending = [...this.attending, ...selected];
    this.recommended = this.filterAttendingRecommendedUsers();

    this.saveDisabled = false;
  };

  getSessionAttendees(id: number) {
    this.attendingIsLoading = true;
    this.recommendedIsLoading = true;
    this.eventsService
      .getTrainingSessionAttendees(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (s: AttendeesAndRecommendations) => {
          this.originalAttending = s.attending;
          this.originalRecommended = s.recommendations;
          this.attending = s.attending.map((a) => {
            a['attended'] = true;
            return a;
          });

          this.recommended = this.filterAttendingRecommendedUsers();
          this.attendingIsLoading = false;
          this.recommendedIsLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.SESSION_ATTENDEES,
            err,
          );
          this.attendingIsLoading = false;
          this.recommendedIsLoading = false;
        },
      });
  }

  filterAttendingRecommendedUsers(): any[] {
    return this.originalRecommended.filter(
      (rc) => !this.attending.some((at) => at.id === rc.id),
    );
  }
}
