import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OrgUnitLookup } from '../../org-unit/services/org-unit.service';
import { PersonBasic } from '../../person/services/person.service';
import { SkillFilterPayload } from '../../skill/services/skill.service';

export interface EventSession {
  attendeeCount: number;
  attendedCount: number;
  endDate: string | undefined;
  externalId: string;
  externalClassroomId?: number;
  id: number;
  instructors: EventSessionInstructor[];
  isSessionCompleted: boolean;
  location: string;
  locationId: number;
  maxCapacity: number;
  minCapacity: number;
  skillId: number;
  startDate: string | undefined;
  title: string;
}

export interface NewEventSession {
  endDate: string;
  instructors: EventSessionInstructor[];
  isSessionCompleted: boolean;
  location: string;
  locationId: number;
  maxCapacity: number;
  minCapacity: number;
  skillId: number;
  startDate: string;
  title: string;
}

export interface EventSessionInstructor {
  displayName: string;
  externalId: number;
  externalUserId: string;
  id: number;
  name: string;
}

export interface TrainingEventPerson {
  id: number;
  displayName: string;
  location: string;
  locationId: number;
  trainingExpiryDate: string;
  completedPrerequisites: boolean;
}

export interface AttendeesAndRecommendations {
  attending: Array<TrainingEventPerson>;
  recommendations: Array<TrainingEventPerson>;
}

export interface ClaimStatusLookup {
  key: string;
  name: string;
  parentKey: string;
  hierarchyKey: string;
}

export interface BulkAssessmentPayload {
  trainingSessionId: number;
  expiryDate: string;
  comments: string;
  people: BulkAssessmentRequestPerson[];
}

export interface BulkAssessmentRequestPerson {
  personId: number;
  externalId: number;
  attended: boolean;
  trainingOutcomeId?: number;
  score?: number;
  comments: string;
  skills: BulkAssessmentRequestSkill[];
}

export interface BulkAssessmentRequestSkill {
  skillId: number;
  functionalAreaId: number;
  claimLevelId: number;
  claimLevelSetId: number;
}

export interface BulkAssessmentResponse {
  trainingSessionId: number;
  skillName: string;
  assessorName: string;
  expiryDate: string;
  comments: string;
  people: BulkAssessmentResponsePerson[];
}

export interface BulkAssessmentResponsePerson {
  personId: number;
  personName: string;
  attended: boolean;
  isPass: boolean;
  outcome: string;
  score: number;
  comments: string;
  skills: BulkAssessmentResponseSkill[];
}

export interface BulkAssessmentResponseSkill {
  skillId: number;
  skillName: string;
  claimLevelName: string;
}

export interface ClaimStatusPayload {
  statusText: string;
  description: string;
  published: boolean;
}

export interface SkillVsEvent {
  id: number;
  name: string;
  description: string;
  existsInEvent: boolean;
  subjects: SkillSubject[];
}

export interface SkillSubject {
  id: number;
  name: string;
}

export interface SkillForEvent {
  id: number;
  eventSkillId: number;
  skillId: number;
  skillName: string;
  skillDescription: string;
  functionalAreaId: number;
  functionalAreaName: string;
  subjects: SkillSubject[];
  claimLevelSetId: number;
  claimLevelId: number;
}

export interface TrainingOutcome {
  id: number;
  isDefault: boolean;
  name: string;
  description: string;
  isSuccess: boolean;
  isActive: boolean;
}

export interface TrainingOutcomePayload {
  name: string;
  description: string;
  isSuccess: boolean;
  isActive: boolean;
}

export enum TrainingOutcomeAction {
  Create = 'C',
  Update = 'U',
  Init = 'I',
}

export interface EventSkillPayload {
  skillId: number;
  claimLevelId: number;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private http = inject(HttpClient);

  //TO DO : Handle errors using a config service as per - https://angular.io/guide/http-handle-request-errors

  apiServerUri = environment.serverUrl;

  public session: EventSession;
  public selectedAttendees;
  public sessionLocations: OrgUnitLookup[] = [];

  getTrainingSessionById(id: number): Observable<EventSession> {
    const url = `${this.apiServerUri}/trainingSessions/${id}`;
    return this.http.get<EventSession>(url);
  }

  getTrainingSessionAttendees(
    id: number,
  ): Observable<AttendeesAndRecommendations> {
    const url = `${this.apiServerUri}/trainingSessions/${id}/attendees`;
    return this.http.get<AttendeesAndRecommendations>(url);
  }

  getTrainingSessionInRange(
    startDateRange: string,
    endDateRange: string,
  ): Observable<EventSession[]> {
    const url = `${this.apiServerUri}/trainingSessions/byRange/${startDateRange}/${endDateRange}`;
    return this.http.get<EventSession[]>(url);
  }

  getTrainersBySkillId(id: number): Observable<PersonBasic[]> {
    const url = `${this.apiServerUri}/trainers/bySkillId/${id}`;
    return this.http.get<PersonBasic[]>(url);
  }

  getBulkAssessment(
    trainingSessionId: number,
  ): Observable<BulkAssessmentResponse> {
    const url = `${this.apiServerUri}/bulkAssessments/${trainingSessionId}`;
    return this.http.get<BulkAssessmentResponse>(url);
  }

  postBulkAssessment(
    bulkAssessmentRequest: BulkAssessmentPayload,
  ): Observable<BulkAssessmentResponse> {
    const url = `${this.apiServerUri}/bulkAssessments`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<BulkAssessmentResponse>(
      url,
      JSON.stringify(bulkAssessmentRequest),
      { headers: headers },
    );
  }

  createTrainingSession(newSession: NewEventSession): Observable<EventSession> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EventSession>(
      `${this.apiServerUri}/trainingSessions`,
      JSON.stringify(newSession),
      { headers: headers },
    );
  }

  getEventTrainersById(id: string): Observable<PersonBasic[]> {
    const url = `${this.apiServerUri}/trainingSessions/${id}/trainers`;
    return this.http.get<PersonBasic[]>(url);
  }

  addAttendees(sessionId: number, peopleIds: number[]) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${this.apiServerUri}/trainingSessions/${sessionId}/attendees/add `;
    return this.http.post(url, JSON.stringify(peopleIds), { headers: headers });
  }

  removeAttendees(sessionId: number, peopleIds: number[]) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${this.apiServerUri}/trainingSessions/${sessionId}/attendees/remove `;
    return this.http.post(url, JSON.stringify(peopleIds), { headers: headers });
  }

  //delete

  getBulkAssessmentClaimStatus(): Observable<ClaimStatusLookup[]> {
    const url = `${this.apiServerUri}/claimStatuses/forBulkAssessment/lookups`;
    return this.http.get<ClaimStatusLookup[]>(url);
  }

  getTrainingOutcomes(): Observable<TrainingOutcome[]> {
    const url = `${this.apiServerUri}/trainingOutcomes`;
    return this.http.get<TrainingOutcome[]>(url);
  }

  getTrainingOutcomesLookup(): Observable<TrainingOutcome[]> {
    const url = `${this.apiServerUri}/lookups/trainingOutcome`;
    return this.http.get<TrainingOutcome[]>(url);
  }

  getSkillsVsEventId(
    eventSkillId: number,
    filterData: SkillFilterPayload,
  ): Observable<SkillVsEvent[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<SkillVsEvent[]>(
      `${this.apiServerUri}/skills/vsEventSkillId/${eventSkillId}`,
      JSON.stringify(filterData),
      { headers: headers },
    );
  }

  getSkillsForEvent(eventSkillId: number): Observable<SkillForEvent[]> {
    return this.http.get<SkillForEvent[]>(
      `${this.apiServerUri}/skillEventSkills/byEventSkillId/${eventSkillId}`,
    );
  }

  addSkillsToEvent(
    eventSkillId: number,
    eventSkills: EventSkillPayload[],
  ): Observable<SkillForEvent[]> {
    return this.http.post<SkillForEvent[]>(
      `${this.apiServerUri}/skillEventSkills/addByEventSkillId/${eventSkillId}`,
      eventSkills,
    );
  }

  removeSkillFromEvent(skillEventIds: number[]): Observable<SkillForEvent[]> {
    return this.http.post<SkillForEvent[]>(
      `${this.apiServerUri}/skillEventSkills/deleteByIds`,
      skillEventIds,
    );
  }

  updateTrainingOutcomeById(
    id: number,
    trainingOutcomeData: TrainingOutcomePayload,
  ): Observable<TrainingOutcome> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<TrainingOutcome>(
      `${this.apiServerUri}/trainingOutcomes/${id}`,
      JSON.stringify(trainingOutcomeData),
      { headers: headers },
    );
  }

  createTrainingOutcome(
    trainingOutcomeData: TrainingOutcomePayload,
  ): Observable<TrainingOutcome> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<TrainingOutcome>(
      `${this.apiServerUri}/trainingOutcomes`,
      JSON.stringify(trainingOutcomeData),
      { headers: headers },
    );
  }

  updateSkillsForEvent(
    eventSkillId: number,
    eventSkills: EventSkillPayload[],
  ): Observable<SkillForEvent[]> {
    return this.http.post<SkillForEvent[]>(
      `${this.apiServerUri}/skillEventSkills/updateByEventSkillId/${eventSkillId}`,
      eventSkills,
    );
  }

  getFutureSessionsBySkillId(skillId: number): Observable<EventSession[]> {
    return this.http.get<EventSession[]>(
      `${this.apiServerUri}/trainingSessions/futureBySkillId/${skillId}`,
    );
  }
}
