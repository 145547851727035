import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  ClaimLevelDictionary,
  ClaimService,
} from '../../../claim/services/claim.service';
import {
  CompetenciesService,
  SkillDimension,
  SkillDimensionId,
  SkillSubject,
} from '../../../competencies/services/competencies.service';
import { DateFilter } from '../../../controls/dynamic-form/control-types/filter-date';
import { DropdownSelectFilter } from '../../../controls/dynamic-form/control-types/filter-select';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import { FilterControlService } from '../../../controls/dynamic-form/filter-control.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { ConfigService } from '../../../core/services/config.service';
import {
  AnalyticsFilter,
  AnalyticsStatus,
  CompetencyAnalyticsStatusKey,
  DashboardService,
  GenerateAnalysisEvent,
  TrainingAnalyticsStatusKey,
} from '../../../dashboard/services/dashboard.service';
import { EventsService } from '../../../events/services/events.service';
import { JobRoleService } from '../../../job-role/services/job-role.service';
import { OrgUnitService } from '../../../org-unit/services/org-unit.service';
import {
  SkillSearch,
  SkillSearchBody,
  SkillSearchDictionary,
  SkillService,
} from '../../../skill/services/skill.service';

@Component({
  selector: 'ug-skill-filters',
  templateUrl: './skill-filters.component.html',
  styleUrl: './skill-filters.component.scss',
})
export class SkillFiltersComponent implements OnInit, OnDestroy {
  private skillService = inject(SkillService);
  private competenciesService = inject(CompetenciesService);
  private filterControlService = inject(FilterControlService);
  private configService = inject(ConfigService);
  private orgUnitService = inject(OrgUnitService);
  private jobRoleService = inject(JobRoleService);
  private claimService = inject(ClaimService);
  private dashboardService = inject(DashboardService);
  private alertService = inject(AlertService);
  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService);

  exceptionData = {
    TRAINING_STATUS: {
      level: AlertLevels.ERROR,
      code: 'CAF-001',
      message: 'Error getting training statuses',
    } as AlertData,
    COMPETENCY_STATUS: {
      level: AlertLevels.ERROR,
      code: 'CAF-002',
      message: 'Error getting competency statuses',
    } as AlertData,
    SKILL_DIMENSIONS: {
      level: AlertLevels.ERROR,
      code: 'CAF-003',
      message: 'Error getting dimensions',
    } as AlertData,
    SUBJECT_DICTIONARY: {
      level: AlertLevels.ERROR,
      code: 'CAF-004',
      message: 'Error getting categories and subcategories',
    } as AlertData,
    TRAINING_STATUSES: {
      level: AlertLevels.ERROR,
      code: 'CAF-005',
      message: 'Error getting training statuses',
    } as AlertData,
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'CAF-006',
      message: 'Error getting claim levels',
    } as AlertData,
    JOB_ROLES: {
      level: AlertLevels.ERROR,
      code: 'CAF-007',
      message: 'Error getting job roles',
    } as AlertData,
    ORG_UNITS: {
      level: AlertLevels.ERROR,
      code: 'CAF-008',
      message: 'Error getting org units',
    } as AlertData,
    COMPETENCY_STATUSES: {
      level: AlertLevels.ERROR,
      code: 'CAF-009',
      message: 'Error getting competency statuses',
    } as AlertData,
  };

  dimensionId: number = SkillDimensionId.Application;
  subjectMap: Map<number, SkillSubject[]> = new Map<number, SkillSubject[]>();
  filtersForm!: FormGroup;
  matchingSkills: SkillSearch[] = [];
  skillsMap: Map<string, SkillSearch[]> = new Map<string, SkillSearch[]>();
  filters: any[] = [];
  additionalFiltersCollapsed = true;
  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  skillDimensions: SkillDimension[] = [];
  levelSets: ClaimLevelDictionary;

  @Input() showData: boolean = false;
  @Output() generateClickEvent: EventEmitter<GenerateAnalysisEvent> =
    new EventEmitter<any>();

  sharedFilters = [
    new DropdownSelectFilter({
      key: 'category',
      filterLoading: true,
      filterClass: 'col-sm-12 col-md-3 mb-2',
      multiple: false,
      label: 'Category',
      options: [],
      placeholder: 'Select Category',
      bindLabel: 'title',
      bindValue: 'id',
    }),
    new DropdownSelectFilter({
      key: 'subcategory',
      filterClass: 'col-sm-12 col-md-3 mb-2',
      multiple: false,
      label: 'Subcategory',
      options: [],
      placeholder: 'Select SubCategory',
      disabled: true,
      bindLabel: 'title',
    }),
    new DropdownSelectFilter({
      key: 'skill',
      filterClass: 'col-sm-12 col-md-10 mb-2',
      multiple: false,
      required: true,
      label:
        this.dimensionId === SkillDimensionId.Training
          ? 'Training search'
          : 'Competency search',
      filterLoading: false,
      options: [],
      typeaheadSubject: new Subject<string>(),
      items: of([]),
      notFoundText: undefined,
      invalidMessage: '',
    }),
    new DropdownSelectFilter({
      key: 'analyticsStatus',
      filterClass: 'col-sm-12 col-md-5',
      multiple: true,
      label: 'Status',
      filterLoading: true,
      options: [],
      disabled: true,
      bindLabel: 'name',
    }),
    new DateFilter({
      key: 'dateFrom',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Date from',
      disabled: true,
      invalidMessage: 'Date from required',
    }),
    new DateFilter({
      key: 'dateTo',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Date to',
      disabled: true,
      invalidMessage: 'Date to required',
    }),
  ];

  trainingFilters = [
    new DropdownSelectFilter({
      key: 'trainingType',
      filterClass: 'col-sm-12 col-md-3 mb-2',
      multiple: false,
      required: true,
      label: 'Type',
      filterLoading: false,
      options: [],
      disabled: true,
    }),
    new DropdownSelectFilter({
      key: 'trainingVersion',
      filterClass: 'col-sm-12 col-md-3 mb-2',
      multiple: false,
      required: false,
      label: 'Version',
      filterLoading: false,
      options: [],
      disabled: true,
    }),
    new DropdownSelectFilter({
      key: 'trainingProvider',
      filterClass: 'col-sm-12 col-md-3 mb-2',
      multiple: false,
      required: true,
      label: 'Provider',
      filterLoading: false,
      options: [],
      disabled: true,
    }),
  ];

  competencyFilters = [
    new DropdownSelectFilter({
      key: 'claimLevel',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Level',
      options: [],
      disabled: true,
      bindLabel: 'name',
    }),
  ];

  additionalFilters = [
    new DropdownSelectFilter({
      key: 'position',
      filterClass: 'col-sm-12 col-md-3',
      multiple: false,
      label: 'Position',
      options: [],
      placeholder: 'Select Position',
      bindLabel: 'name',
      filterLoading: true,
    }),
    new DropdownSelectFilter({
      key: 'location',
      filterClass: 'col-sm-12 col-md-3',
      multiple: false,
      label: 'Location',
      options: [],
      placeholder: 'Select Location',
      bindLabel: 'name',
      filterLoading: true,
    }),
    new DropdownSelectFilter({
      key: 'division',
      filterClass: 'col-sm-12 col-md-3',
      multiple: false,
      label: 'Division',
      options: [],
      placeholder: 'Select Division',
      bindLabel: 'name',
      filterLoading: true,
    }),
    new DropdownSelectFilter({
      key: 'jobrole',
      filterClass: 'col-sm-12 col-md-3',
      multiple: false,
      label: 'Job Role',
      options: [],
      placeholder: 'Select Job Role',
      bindLabel: 'name',
      filterLoading: true,
    }),
  ];

  trainingAnalyticsStatusOptions: { key: string; value: AnalyticsFilter }[] =
    [];
  competencyAnalyticsStatusOptions: { key: string; value: AnalyticsFilter }[] =
    [];
  dimensionForm: FormGroup;
  loadingDimensions: boolean;
  combinedFiltersArray: (DropdownSelectFilter | DateFilter)[];

  get trainingTypes(): Array<string> {
    const trainingTypes = [
      ...new Set<string>(this.matchingSkills?.map((y) => y.type)),
    ].sort();
    return trainingTypes ?? [];
  }

  get trainingVersions(): Array<string> {
    const trainingVersions = [
      ...new Set<string>(
        this.matchingSkills
          .filter(
            (ms) => ms.type === this.filtersForm.get('trainingType')?.value,
          )
          .map((sk) => sk.version),
      ),
    ].sort((a, b) => {
      const firstItem = parseFloat(a);
      const secondItem = parseFloat(b);
      let retVal: number;
      if (Number.isNaN(firstItem) && Number.isNaN(secondItem)) {
        retVal = 0;
      } else if (Number.isNaN(firstItem)) {
        retVal = 1;
      } else if (Number.isNaN(secondItem)) {
        retVal = -1;
      } else {
        retVal = firstItem - secondItem;
      }
      return retVal;
    });

    return trainingVersions ?? [];
  }

  get trainingProviders(): Array<string> {
    const trainingProviders = [
      ...new Set<string>(
        this.matchingSkills
          .filter(
            (ms) =>
              ms.type === this.filtersForm.get('trainingType')?.value &&
              ms.version === this.filtersForm.get('trainingVersion')?.value,
          )
          .map((sk) => sk.provider),
      ),
    ].sort();
    return trainingProviders ?? [];
  }

  get selectedSkill(): SkillSearch | undefined {
    return this.matchingSkills.find(
      (ms) =>
        ms.type === this.filtersForm.get('trainingType')?.value &&
        ms.version === this.filtersForm.get('trainingVersion')?.value &&
        ms.provider === this.filtersForm.get('trainingProvider')?.value,
    );
  }

  get categoryFormControl(): AbstractControl | null {
    return this.filtersForm.get('category');
  }

  get subcategoryFormControl(): AbstractControl | null {
    return this.filtersForm.get('subcategory');
  }

  get trainingTypeControl(): AbstractControl | null {
    return this.filtersForm.get('trainingType');
  }

  get trainingVersionControl(): AbstractControl | null {
    return this.filtersForm.get('trainingVersion');
  }

  get trainingProviderControl(): AbstractControl | null {
    return this.filtersForm.get('trainingProvider');
  }

  ngOnInit() {
    this.combinedFiltersArray = [
      ...this.sharedFilters,
      ...this.trainingFilters,
      ...this.competencyFilters,
      ...this.additionalFilters,
    ];

    this.dimensionForm = this.fb.group({
      dimensionId: [this.dimensionId],
    });

    this.dimensionForm
      .get('dimensionId')
      .valueChanges.pipe(
        startWith(this.dimensionId),
        pairwise(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([prev, next]) => {
        this.dimensionId = next;
        if (this.showData) {
          this.generateClickEvent.emit(null);
        }

        this.updateCategoryList();

        const analyticsStatusFilter = this.getFilterByKey('analyticsStatus');
        this.skillFilter.invalidMessage = '';

        if (
          prev !== SkillDimensionId.Training &&
          next === SkillDimensionId.Training
        ) {
          this.filters = [...this.sharedFilters];
          this.filters.splice(3, 0, ...this.trainingFilters);
          analyticsStatusFilter.options = this.trainingAnalyticsStatusOptions;
          this.skillFilter.label = 'Training search';
        } else if (
          prev === SkillDimensionId.Training &&
          next !== SkillDimensionId.Training
        ) {
          this.filters = [...this.sharedFilters, ...this.competencyFilters];
          analyticsStatusFilter.options = this.competencyAnalyticsStatusOptions;
          this.skillFilter.label = 'Competency search';
        }

        if (this.filtersForm && this.filtersForm.touched) {
          Object.keys(this.filtersForm.controls).forEach((key) => {
            const c = this.combinedFiltersArray.find((f) => f.key === key);
            this.filtersForm
              .get(key)
              .reset(
                { value: null, disabled: c.disabled },
                { emitEvent: false },
              );
          });

          this.updateRequiredValidators(['dateFrom', 'dateTo'], true);
        }
      });

    this.filtersForm = this.filterControlService.toFormGroup(
      this.combinedFiltersArray as FilterBase<string>[],
    );

    this.loadingDimensions = true;
    this.filters = [...this.sharedFilters];

    this.skillService
      .getSkillDimensions()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((dimensions) => {
          this.loadingDimensions = false;
          this.skillDimensions = dimensions;
        }),
        catchError((error) => {
          this.alertService.createAlert2(
            this.exceptionData.SKILL_DIMENSIONS,
            error,
          );
          return of([]);
        }),
        switchMap(() => this.competenciesService.getSubjectsDictionary()),
      )
      .subscribe({
        next: (subjects) => {
          for (const key in subjects) {
            if (subjects.hasOwnProperty(key)) {
              const values = subjects[key].filter((s) => s.isActive);
              this.subjectMap.set(Number(key), values);
            }
          }
          this.updateCategoryList();
          this.getFilterByKey('category').filterLoading = false;
          this.loadingDimensions = false;
        },
        error: (error) => {
          this.alertService.createAlert2(
            this.exceptionData.SUBJECT_DICTIONARY,
            error,
          );
        },
      });

    this.dashboardService.getSkillAnalyticsTrainingStatuses().subscribe({
      next: (trainingStatuses) => {
        this.trainingAnalyticsStatusOptions = trainingStatuses.map(
          (ts: any) => ({ key: ts.name, value: ts }),
        );
      },
      error: (error) => {
        this.alertService.createAlert2(
          this.exceptionData.TRAINING_STATUSES,
          error,
        );
      },
    });

    this.dashboardService
      .getSkillAnalyticsCompetencyStatuses()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (competencyStatuses) => {
          this.competencyAnalyticsStatusOptions = competencyStatuses.map(
            (ts: any) => ({ key: ts.name, value: ts }),
          );
          this.filters = [...this.sharedFilters, ...this.competencyFilters];
          const analyticsStatusFilter = this.getFilterByKey('analyticsStatus');
          analyticsStatusFilter.options = this.competencyAnalyticsStatusOptions;
          this.getFilterByKey('analyticsStatus').filterLoading = false;
        },
        error: (error) => {
          this.alertService.createAlert2(
            this.exceptionData.COMPETENCY_STATUSES,
            error,
          );
        },
      });

    this.claimService
      .getAllClaimLevels()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (claimLevels) => {
          this.levelSets = claimLevels;
        },
        error: (error) => {
          this.alertService.createAlert2(
            this.exceptionData.CLAIM_LEVELS,
            error,
          );
        },
      });

    this.jobRoleService
      .getJobRolesDotNet()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (jobRoles) => {
          const jobRoleFilter = this.getAdditionalFilterByKey('jobrole');
          jobRoleFilter.options = jobRoles.map((ts: any) => ({
            key: ts.name,
            value: ts,
          }));
          jobRoleFilter.filterLoading = false;
        },
        error: (error) => {
          this.alertService.createAlert2(this.exceptionData.JOB_ROLES, error);
        },
      });

    const ouObservables: Observable<any>[] = [
      this.orgUnitService.getOrgUnitLookups({
        externalTypeId: '2',
        source: 'CSOD',
      }),
      this.orgUnitService.getOrgUnitLookups({
        externalTypeId: '4',
        source: 'CSOD',
      }),
      this.orgUnitService.getOrgUnitLookups({
        externalTypeId: '32',
        source: 'CSOD',
      }),
    ];

    forkJoin(ouObservables)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        defaultIfEmpty([]),
        map(([divisions, positions, locations]) => ({
          divisions,
          positions,
          locations,
        })),
      )
      .subscribe({
        next: (ou) => {
          this.getAdditionalFilterByKey('position').options = ou.positions.map(
            (ts: any) => ({ key: ts.name, value: ts }),
          );
          this.getAdditionalFilterByKey('location').options = ou.locations.map(
            (ts: any) => ({ key: ts.name, value: ts }),
          );
          this.getAdditionalFilterByKey('division').options = ou.divisions.map(
            (ts: any) => ({ key: ts.name, value: ts }),
          );
          this.getAdditionalFilterByKey('position').filterLoading = false;
          this.getAdditionalFilterByKey('location').filterLoading = false;
          this.getAdditionalFilterByKey('division').filterLoading = false;
          this.eventsService.sessionLocations = ou.locations;
        },
        error: (error) => {
          this.alertService.createAlert2(this.exceptionData.ORG_UNITS, error);
        },
      });

    this.categoryFormControl?.valueChanges.subscribe((category) => {
      if (
        this.filtersForm.get('skill').value &&
        this.filtersForm.get('skill').hasError('invalid')
      ) {
        this.filtersForm.get('skill')?.setValue(null, { emitEvent: false });
        this.filtersForm.get('skill')?.setErrors({ invalid: false });
        this.skillFilter.invalidMessage = '';
      }

      this.updateSubcategoryFilterOptions(category?.id);
    });

    this.filtersForm.get('skill')?.valueChanges.subscribe((skill) => {
      this.matchingSkills = this.skillsMap.get(skill) ?? [];
      if (
        this.dimensionId !== SkillDimensionId.Training &&
        this.matchingSkills.length > 1
      ) {
        this.skillFilter.invalidMessage = skill
          ? 'Multiple competencies found. Please use the category and subcategory filters to narrow down your search.'
          : '';
        this.filtersForm.get('skill')?.setErrors({ invalid: true });
      } else {
        this.filtersForm.get('skill')?.setErrors(null);
        this.skillFilter.invalidMessage = '';

        this.toggleCategoryAndSubcategory(skill);
        this.updateTrainingTypeFilter(skill);
      }
    });

    this.filtersForm.get('trainingType')?.valueChanges.subscribe((type) => {
      this.updateTrainingVersionFilter(type);
    });

    this.filtersForm
      .get('trainingVersion')
      ?.valueChanges.subscribe((version) => {
        this.updateTrainingProviderFilter(version);
      });

    this.filtersForm
      .get('trainingProvider')
      ?.valueChanges.subscribe((provider) => {
        if (!this.selectedSkill) {
          this.filtersForm
            .get('analyticsStatus')
            ?.setValue(null, { emitEvent: false });
          this.filtersForm
            .get('dateFrom')
            ?.setValue(null, { emitEvent: false });
          this.filtersForm.get('dateTo')?.setValue(null, { emitEvent: false });
          this.updateControlDisabledState(
            ['analyticsStatus', 'dateFrom', 'dateTo'],
            true,
          );
        } else {
          this.updateControlDisabledState([
            'analyticsStatus',
            'dateFrom',
            'dateTo',
          ]);
        }
      });

    this.filtersForm.get('analyticsStatus')?.valueChanges.subscribe((cs) => {
      this.handleAnalyticsStatusChanges(cs);
    });

    this.setSkillSearchTypeahead();
  }

  getSkillSearch(searchTerm: string = ''): Observable<any> {
    const searchFilters: SkillSearchBody = {
      dimensionId: this.dimensionId,
      name: searchTerm,
      maxCount: 50,
      types:
        this.dimensionId === SkillDimensionId.Training
          ? this.configService.jobRoleSkillSearchTypes
          : [],
      subjectId: this.skillSubjectIdFilter,
    };
    return this.skillService.getSkillsByAnalyticsSearch(searchFilters).pipe(
      map((s: SkillSearchDictionary) => {
        this.skillsMap = new Map(Object.entries(s));
        return Object.keys(s);
      }),
      catchError(() => of([])),
      tap((res: any) => {
        this.skillFilter.filterLoading = false;
        if (!res.size) {
          this.skillFilter.notFoundText =
            'No results found for ' +
            searchFilters.name +
            '. Please enter a different search criteria.';
        }
      }),
    );
  }

  getFilterByKey(key: string): any {
    return this.filters.find((f) => f.key == key);
  }

  getAdditionalFilterByKey(key: string): any {
    return this.additionalFilters.find((f) => f.key == key);
  }

  get summaryControls() {
    const selectedControlNames = [
      'position',
      'division',
      'jobrole',
      'location',
    ];
    return selectedControlNames.map((name) => {
      return this.filtersForm.get(name);
    });
  }

  updateSubcategoryFilterOptions(categoryId: number): void {
    const subcategoryFilter = this.getFilterByKey('subcategory');
    if (categoryId) {
      this.subcategoryFormControl?.reset({ value: null, disabled: false });
      subcategoryFilter.options =
        this.subjectMap
          .get(categoryId)
          ?.filter((subj) => subj.dimensionIds.includes(this.dimensionId))
          ?.map((subj) => ({ key: subj.name, value: subj })) ?? [];
    } else {
      this.subcategoryFormControl?.reset({ value: null, disabled: true });
      subcategoryFilter.options = [];
    }
  }

  toggleCategoryAndSubcategory(skill: any): void {
    if (skill) {
      this.categoryFormControl?.disable({ emitEvent: false });
      this.subcategoryFormControl?.disable({ emitEvent: false });
    } else {
      this.categoryFormControl?.reset({ value: null, disabled: false });
      this.subcategoryFormControl?.reset({ value: null, disabled: true });
    }
  }

  handleAnalyticsStatusChanges(statuses: AnalyticsStatus[]): void {
    if (!statuses) {
      this.updateControlDisabledState(['dateFrom', 'dateTo']);
    } else {
      const isAwaitingAssessment =
        this.dimensionId !== SkillDimensionId.Training &&
        statuses?.length === 1 &&
        statuses[0].key === CompetencyAnalyticsStatusKey.Open;
      const includesExpiring = statuses?.some(
        (status) => status.key === TrainingAnalyticsStatusKey.Expiring,
      );
      isAwaitingAssessment
        ? this.updateControlDisabledState(['dateFrom', 'dateTo'], true)
        : this.updateControlDisabledState(['dateFrom', 'dateTo']);
      this.updateRequiredValidators(['dateFrom', 'dateTo'], !includesExpiring);
    }
  }

  updateControlDisabledState(controls: string[], disable: boolean = false) {
    controls.forEach((controlName) => {
      disable
        ? this.filtersForm.get(controlName)?.disable({ emitEvent: false })
        : this.filtersForm.get(controlName)?.enable({ emitEvent: false });
    });
  }

  updateRequiredValidators(controls: string[], remove: boolean = false) {
    controls.forEach((controlName) => {
      remove
        ? this.filtersForm
            .get(controlName)
            ?.removeValidators([Validators.required])
        : this.filtersForm
            .get(controlName)
            ?.addValidators([Validators.required]);
      this.filtersForm.get(controlName)?.updateValueAndValidity();
    });
  }

  updateTrainingTypeFilter(skill: string): void {
    this.updateFilterControl(
      'trainingType',
      this.trainingTypes,
      skill,
      this.trainingTypeControl,
    );
  }

  updateTrainingVersionFilter(type: string) {
    this.updateFilterControl(
      'trainingVersion',
      this.trainingVersions,
      type,
      this.trainingVersionControl,
    );
  }

  updateTrainingProviderFilter(version: string) {
    this.updateFilterControl(
      'trainingProvider',
      this.trainingProviders,
      version,
      this.trainingProviderControl,
    );
    if (this.dimensionId !== SkillDimensionId.Training) {
      const claimLevelSetId = this.selectedSkill
        ? this.selectedSkill.claimLevelSetId
        : null;
      const options = claimLevelSetId
        ? this.levelSets[claimLevelSetId?.toString()].map((ts: any) => ({
            key: ts.name,
            value: ts,
          }))
        : [];
      this.updateFilterControl(
        'claimLevel',
        options,
        claimLevelSetId,
        this.filtersForm.get('claimLevel'),
      );
    }
  }

  updateFilterControl(
    filterKey: string,
    options: any[],
    dependentValue: any,
    control: AbstractControl | null,
  ): void {
    const filter = this.getFilterByKey(filterKey);
    if (filter) {
      filter.options = options; // Update the options for the filter
    }
    const value = options.length === 1 ? options[0]?.value ?? options[0] : null;
    const disabled = options.length === 1 || !dependentValue;
    control?.reset({ value: value, disabled: disabled });
  }

  updateCategoryList() {
    if (this.skillDimensions.length && this.subjectMap.size) {
      const categoryList = this.subjectMap
        .get(0)
        ?.filter((v) => v.dimensionIds.includes(this.dimensionId))
        .map((op) => ({ key: op.name, value: op }));
      const categoryFilter = this.getFilterByKey('category');
      if (categoryFilter) {
        categoryFilter.options = categoryList ?? [];
      }
    }
  }

  setSkillSearchTypeahead() {
    this.skillFilter.items = this.skillFilter.typeaheadSubject.pipe(
      filter((res: string | null) => {
        return res === null || res.length >= 2;
      }),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((term: string) => {
        if (!term) {
          //when dimension changes or skill cleared, return an empty array
          return of([]);
        }
        this.skillFilter.filterLoading = true;
        return this.getSkillSearch(term);
      }),
    );
  }

  onGenerateClick() {
    if (this.selectedSkill && this.filtersForm.valid) {
      const orgUnitIds = [];
      if (this.filtersForm.get('position')?.value?.key) {
        orgUnitIds.push(Number(this.filtersForm.get('position')?.value?.key));
      }
      if (this.filtersForm.get('division')?.value?.key) {
        orgUnitIds.push(Number(this.filtersForm.get('division')?.value?.key));
      }
      if (this.filtersForm.get('location')?.value?.key) {
        orgUnitIds.push(Number(this.filtersForm.get('location')?.value?.key));
      }

      const analyticsFilter: AnalyticsFilter = {
        skillId: this.selectedSkill.id,
        analyticStatuses: this.filtersForm.get('analyticsStatus')?.value?.length
          ? this.filtersForm.get('analyticsStatus')?.value?.map((as) => as.key)
          : undefined,
        claimLevelId: this.filtersForm.get('claimLevel')?.value?.key
          ? Number(this.filtersForm.get('claimLevel')?.value?.key)
          : undefined,
        fromDate: this.filtersForm.get('dateFrom')?.value ?? undefined,
        toDate: this.filtersForm.get('dateTo')?.value ?? undefined,
        orgUnitIds: orgUnitIds.length ? orgUnitIds : undefined,
        jobRoleIds: this.filtersForm.get('jobrole')?.value?.id
          ? [this.filtersForm.get('jobrole')?.value?.id]
          : undefined,
        skillName: this.selectedSkill.name,
      };

      const generateAnalysisEvent: GenerateAnalysisEvent = {
        filters: analyticsFilter,
        skillType: this.selectedSkill.type,
        dimensionId: this.dimensionId,
      };

      this.generateClickEvent.emit(generateAnalysisEvent);
      this.filtersForm.markAsPristine();
    }
  }

  onResetClick() {
    this.filtersForm.reset(null);
    this.updateRequiredValidators(['dateFrom', 'dateTo'], true);
    this.generateClickEvent.emit(null);
  }

  get skillSubjectIdFilter(): number | undefined {
    return (
      this.filtersForm.get('subcategory')?.value?.id ??
      this.filtersForm.get('category')?.value?.id
    );
  }

  get listLength() {
    return this.loadingDimensions ? 0 : 1;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  get skillFilter() {
    return this.getFilterByKey('skill');
  }
}
