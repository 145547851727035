<div>
  <div *ngIf="!allowEdit" class="fst-italic fw-bold mb-2">
    <p class="fst-italic fw-bold">
      You do not have permission to edit Role Training
    </p>
  </div>
  <div *ngIf="allowEdit" class="mb-4">
    <button
      type="button"
      class="btn esqepBtn"
      (click)="viewSettings = !viewSettings"
    >
      {{ !viewSettings ? 'View' : 'Hide' }} Settings
    </button>
    <button
      *ngIf="!addingReqInProgress"
      type="button"
      class="btn esqepBtn ms-2"
      (click)="onAddRequirementClick()"
      [disabled]="addingReqInProgress || loadingSettings"
    >
      Add Training
    </button>
  </div>

  <div [ngbCollapse]="!viewSettings">
    <ug-role-due-date-settings
      [settingsFormGroup]="parentFormGroup.get('settings')"
      [allowEdit]="allowEdit"
      [trainingLength]="training.length"
      (showSelectBoxEvent)="showSelectBox($event)"
      (confirmSettingsEvent)="confirmSettings($event)"
    ></ug-role-due-date-settings>
  </div>

  <div
    class="alert alert-info mt-3"
    [hidden]="training.length > 0 || addingReqInProgress"
  >
    <i class="fas fa-xl fa-info-circle me-2"></i>No training has been added to
    this role
  </div>

  <div [ngbCollapse]="!addingReqInProgress">
    <form
      class="card p-3 my-3 bg-light overflow-visible"
      [formGroup]="fgNewTraining"
    >
      <div
        *ngIf="selectedEventHasNoSessions"
        class="alert alert-warning mb-3 py-2"
      >
        <i class="fas fa-exclamation-triangle pe-2"></i>The selected Event LO
        has no sessions defined
      </div>
      <div class="row">
        <div class="d-flex align-items-end mb-2">
          <div class="col-lg-9 col-12">
            <ug-typeahead-search
              (closeSearchEvent)="clearSearchBox()"
              (userSelectedResult)="setSelectedResult($event)"
              [selectedSkill]="selectedSkill"
            ></ug-typeahead-search>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-end">
        <div class="col-12 col-lg-2">
          <label
            for="training-type-after-value-control"
            class="form-label fw-bold mb-1"
            >Training type</label
          >
          <select
            class="form-select"
            id="training-type-after-value-control"
            formControlName="trainingType"
            title="Training type"
          >
            <option selected [value]="null" disabled>Select type...</option>
            <option *ngFor="let type of trainingTypes" [value]="type">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-2">
          <label
            for="training-version-after-value-control"
            class="form-label fw-bold mb-1"
            >Version</label
          >
          <select
            class="form-select"
            id="training-version-after-value-control"
            formControlName="trainingVersion"
            title="Training version"
          >
            <option
              *ngIf="!this.fgNewTraining.get('trainingVersion').dirty"
              selected
              value=""
              disabled
            >
              Select version...
            </option>
            <option *ngFor="let ver of trainingVersions" [value]="ver">
              {{ ver }}
            </option>
          </select>
        </div>
        <div class="col-12 col-lg-2">
          <label
            for="training-provider-after-value-control"
            class="form-label fw-bold mb-1"
            >Provider</label
          >
          <input
            type="text"
            class="form-control"
            id="training-provider-after-value-control"
            formControlName="provider"
            title="Training provider"
          />
        </div>
        <div class="col-12 col-lg-2">
          <label for="trainingOwner" class="form-label fw-bold mb-1"
            >Owner</label
          >
          <input
            type="text"
            class="form-control"
            id="trainingOwner"
            formControlName="owner"
          />
        </div>
        <div class="col-12 col-lg-4 text-end">
          <div class="btn-group btn-group-sm">
            <button
              type="button"
              class="btn btn-outline-danger"
              (click)="cancelTrainingItem()"
            >
              Cancel
            </button>
            <button
              [disabled]="
                dupeSkill ||
                fgNewTraining.invalid ||
                !fgNewTraining.get('skillName').value ||
                !selectedSkill ||
                fetchingCurriculumLOs
              "
              type="button"
              class="btn btn-outline-success ms-2"
              (click)="addTrainingItem()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span *ngIf="dupeSkill" class="text-danger">
            <span class="fas fa-exclamation-triangle"></span>
            Duplicate - Learning Object already exists in this role as an
            individual LO or as part of a curriculum.
          </span>
        </div>
      </div>
    </form>
  </div>

  <div
    [hidden]="training.controls?.length < 1"
    class="table-responsive my-2 table-height"
  >
    <table class="table" [formGroup]="parentFormGroup">
      <thead class="sticky-table-header">
        <tr style="border-bottom: 1px solid lightgray">
          <th *ngIf="showRequirementSelectBox"></th>
          <th scope="col" class="lo-col">
            <div>Learning Object</div>
            <div>[Type / Version / Provider / Owner ]</div>
          </th>

          <th scope="col" class="input-group-col">Recurs Every</th>
          <th scope="col" class="input-group-col">Due After</th>
          <th scope="col" class="input-group-col">Reversioning Due</th>
          <th scope="col" class="input-group-col">New Hire Due</th>
          <th scope="col" class="text-center">Keep Due Dates</th>
          <th scope="col">Mandatory</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody formArrayName="training">
        <ng-container
          *ngFor="let roleRequirement of training.controls; let i = index"
          [formGroupName]="i"
        >
          <tr
            [ngClass]="{ 'border-start-danger-thick': roleRequirement?.errors }"
            style="vertical-align: middle; border-bottom: 1px solid lightgray"
            [ngClass]="{
              'border-start-danger-thick': roleRequirement?.errors,
              'curric-row': roleRequirement.value.trainingType === 'Curriculum',
            }"
            ug-requirement-row
            [isCurricChild]="false"
            [formGroup]="roleRequirement"
            [roleRequirement]="roleRequirement"
            [rowIndex]="i"
            [showRequirementSelectBox]="showRequirementSelectBox"
            [allowEdit]="allowEdit"
            (removeRequirementEvent)="onRemoveRequirementClick($event)"
            (discardAddedRequirementEvent)="
              onDiscardAddedRequirementClick($event)
            "
            (onDiscardChangeRequirementEvent)="
              onDiscardChangeRequirement($event)
            "
            (toggleRowEvent)="toggleRow($event)"
            (selectedRequirementEvent)="selectedRequirement($event)"
            (restoreRequirementEvent)="onRestoreRequirementClick($event)"
          ></tr>

          <tr *ngIf="roleRequirement?.errors">
            <td colspan="8" class="text-danger">
              *{{ roleRequirement?.errors }}
            </td>
          </tr>

          <ng-container
            *ngIf="
              roleRequirement.get('children') &&
              collapsedRowMap.get(roleRequirement.value.skillId)
            "
          >
            <ng-container formArrayName="children">
              <ng-container
                *ngFor="
                  let childRequirement of roleRequirement.get('children')
                    .controls;
                  let j = index
                "
                [formGroupName]="j"
              >
                <tr
                  style="
                    vertical-align: middle;
                    border-bottom: 1px solid lightgray;
                  "
                  [ngClass]="{
                    'border-start-danger-thick': roleRequirement?.errors,
                  }"
                  ug-requirement-row
                  [isCurricChild]="true"
                  [formGroup]="childRequirement"
                  [roleRequirement]="childRequirement"
                  [rowIndex]="j"
                  [parentSkillId]="roleRequirement.value.skillId"
                  [parentId]="roleRequirement.value.id"
                  [showRequirementSelectBox]="showRequirementSelectBox"
                  [allowEdit]="allowEdit"
                  (onDiscardChangeRequirementEvent)="
                    onDiscardChangeRequirement($event)
                  "
                  (selectedRequirementEvent)="selectedRequirement($event)"
                  (restoreRequirementEvent)="onRestoreRequirementClick($event)"
                ></tr>
                <tr *ngIf="childRequirement?.errors">
                  <td colspan="8" class="text-danger">
                    *{{ roleRequirement?.errors }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <ng-container *ngIf="childLOsAreDuplicated && training.dirty">
    <p class="fw-bold text-danger">
      *Some of the curriculum assigned to the role contain shared child LOs.
      Please ensure they have the same values before submitting.
    </p>
  </ng-container>

  <ng-container *ngIf="parentFormGroup.get('id').value">
    <div class="my-2">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary ms-1"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseAudit"
      >
        {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
      </button>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ug-audit-table
        [auditEndpoint]="requirementAudit"
        [id]="parentFormGroup.get('id').value"
        contextKey="requirementName"
        contextTitle="Requirement"
        auditTitle="Requirement"
      ></ug-audit-table>
    </div>
  </ng-container>
</div>
