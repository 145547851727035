<div [formGroup]="form">
  <div [ngSwitch]="filter.controlType">
    <ng-container *ngSwitchCase="'textbox'">
      <label [attr.for]="filter.key" class="form-label">{{
        filter.label
      }}</label>
      <ng-container *ngIf="filter.tooltip">
        <span
          placement="top"
          [ngbTooltip]="filter.tooltip"
          class="ms-2 text-end"
        >
          <i class="fa-solid fa-circle-info"></i>
        </span>
      </ng-container>
      <input
        [formControlName]="filter.key"
        [id]="filter.key"
        [type]="filter.type"
        class="form-control"
        [placeholder]="filter.placeholder"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'typeahead'">
      <ug-dropdown-select
        [ngbTypeahead]="filter.typeAheadSearch"
        [resultFormatter]="filter.resultFormatter"
        [inputFormatter]="filter.inputFormatter"
        [inlineGroup]="filter.inLineGroup"
        [formControlName]="filter.key"
      >
        {{ filter.label }}
      </ug-dropdown-select>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdown'">
      <label
        [hidden]="rowIndex !== 0 && filter.showFirstLabel"
        [attr.for]="filter.key"
        class="form-label"
        >{{ filter.label }}</label
      >
      <select
        [id]="filter.key"
        *ngSwitchCase="'dropdown'"
        [formControlName]="filter.key"
        class="form-select"
      >
        <option
          *ngFor="let opt of filter.options"
          [ngValue]="opt?.value"
          [hidden]="opt?.hide"
        >
          {{ opt.key }}
        </option>
      </select>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdownSelect'">
      <label
        [hidden]="rowIndex !== 0 && filter.showFirstLabel"
        [attr.for]="filter.key"
        class="form-label"
        >{{ filter.label }}</label
      >

      <ng-container *ngIf="!filter.typeaheadSubject">
        <ng-select
          [id]="filter.key"
          [multiple]="filter.multiple"
          [maxSelectedItems]="filter.maxSelectedItems"
          [formControlName]="filter.key"
          [loading]="filter.filterLoading"
          [bindLabel]="filter.bindLabel"
          [placeholder]="filter.placeholder"
          class="tableDropdown"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div
              class="ng-value"
              *ngFor="
                let item of items
                  | slice: 0 : filter.maxDisplayItems || items.length
              "
            >
              <ng-container>
                <span class="ng-value-label">{{ item[filter.bindLabel] }}</span>
                <span
                  class="ng-value-icon right"
                  (click)="clear(item)"
                  aria-hidden="true"
                  >×</span
                >
              </ng-container>
            </div>
            <div
              class="ng-value"
              *ngIf="
                filter.maxDisplayItems && items.length > filter.maxDisplayItems
              "
            >
              <span class="ng-value-label"
                >{{ items.length - filter.maxDisplayItems }} more...</span
              >
            </div>
          </ng-template>
          <ng-container *ngFor="let opt of filter.options">
            <ng-option *ngIf="!opt?.hide" [value]="opt?.value ?? opt">{{
              opt?.key ?? opt
            }}</ng-option>
          </ng-container>
        </ng-select>
      </ng-container>

      <ng-container *ngIf="filter.typeaheadSubject">
        <ng-select
          [items]="filter.items | async"
          typeToSearchText="Please enter 2 or more characters"
          [typeahead]="filter.typeaheadSubject"
          [multiple]="filter.multiple"
          [bindLabel]="filter.bindLabel"
          [maxSelectedItems]="filter.maxSelectedItems"
          [clearSearchOnAdd]="true"
          [formControlName]="filter.key"
          [loading]="filter.filterLoading"
          [notFoundText]="filter.notFoundText"
        >
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <span [ngOptionHighlight]="search">{{
              filter.bindLabel ? item[filter.bindLabel] : item
            }}</span>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div
              class="ng-value"
              *ngFor="
                let item of items
                  | slice: 0 : filter.maxDisplayItems || items.length
              "
            >
              <ng-container>
                <span class="ng-value-label">{{
                  filter.bindLabel ? item[filter.bindLabel] : item
                }}</span>
                <span
                  class="ng-value-icon right"
                  (click)="clear(item)"
                  aria-hidden="true"
                  >×</span
                >
              </ng-container>
            </div>
            <div
              class="ng-value"
              *ngIf="
                filter.maxDisplayItems && items.length > filter.maxDisplayItems
              "
            >
              <span class="ng-value-label"
                >{{ items.length - filter.maxDisplayItems }} more...</span
              >
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="filter.invalidMessage" class="invalid-feedback">
          {{ filter.invalidMessage }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <label
        class="form-label"
        [hidden]="rowIndex !== 0 && filter.showFirstLabel"
        [attr.for]="filter.key"
        >{{ filter.label }}</label
      >
      <input
        class="form-control"
        type="date"
        [formControlName]="filter.key"
        [id]="filter.key"
        [title]="filter.key"
      />
      <div *ngIf="filter.invalidMessage" class="invalid-feedback">
        {{ filter.invalidMessage }}
      </div>
    </ng-container>
  </div>
</div>
