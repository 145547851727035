<div class="mx-3 my-2">
  <div class="row">
    <div class="col-7">
      <div class="card h-100">
        <div class="card-body">
          <h5>People in Group: {{ peopleCount }}</h5>
          <ug-table
            [tableHeaders]="headers"
            [tableRows]="tableRows"
            [tablePageIndex]="tablePageIndex"
            [tablePageSize]="tablePageSize"
            [tableFilters]="filters"
            [showSelectBox]="false"
            [isLoading]="tableLoading"
            [totalRecords]="peopleCount"
            [pagedApi]="true"
            (pageIndexChange)="onPageChange($event)"
            (pageSizeChange)="onPageSizeChange($event)"
            (sortChange)="onSortChange($event)"
            (filtersCleared)="clearFilters($event)"
          ></ug-table>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title mb-1">Group Filters Summary</h5>
          <div class="text-break" *ngFor="let groups of filterSummary">
            <div
              *ngIf="groups === 'and' || groups === 'or'; else group"
              class="fw-bold w-100 text-center"
            >
              {{ groups | uppercase }}
            </div>
            <ng-template #group>
              <div class="card">
                <div class="card-body">
                  <div *ngFor="let filter of groups">
                    {{ filter }}
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-3">
  <button
    type="button"
    class="btn btn-sm btn-outline-secondary ms-1"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseAudit"
  >
    {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
  </button>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <ug-group-filter-audit
    [groupFilterId]="groupFilterId"
  ></ug-group-filter-audit>
</div>
